import React, { useRef } from 'react'
import { Content, Filter, Layout } from 'types'
import useAllPosts from 'hooks/use-all-posts'
import useAllProjects from 'hooks/use-all-projects'
import useAllTags from 'hooks/use-tags'
import ListItem from 'components/list/list-item'
import styles from './index.module.css'

function NoContent() {
  return null
}

// Renders a collection of posts
function AllPosts() {
  const allMdx = useAllPosts()

  return allMdx.length > 0 ? allMdx.map(mdx => (
    <ListItem
      key={mdx.id}
      image={mdx.frontmatter.image}
      title={mdx.frontmatter.title}
      subtitles={[
        mdx.frontmatter.displayTime
      ]}
      path={mdx.fields.path}
    />
  )) : (<NoContent />)
}

// Renders a collection of projects
function AllProjects() {
  const allMdx = useAllProjects()

  return allMdx.map(mdx => (
    <ListItem
      key={mdx.id}
      title={mdx.frontmatter.title}
      subtitles={[
        mdx.excerpt,
        mdx.frontmatter.displayTime
      ]}
      path={mdx.fields.path}
    />
  ))
}

// Renders a collection of tags
function AllTags() {
  const allMdx = useAllTags()

  return allMdx.map(mdx => (
    <ListItem
      key={mdx.title}
      title={mdx.title}
      subtitle={`${mdx.count} items`}
      path={`tags/${mdx.title}`}
    />
  ))
}

// Renders a custom collection of content
const CustomEdges = ({ edges }) => {
  return edges.map((edge) => edge.node).map(node => {
    return (
      <ListItem
        key={node.id}
        title={node.frontmatter.title}
        subtitle={node.frontmatter.publishTime}
        path={node.fields.path}
      />
    )
  })
}

// Renders a collection of content with pagination
const Collection = ({ content }) => {
  const element = useRef(null)

  if (content && Array.isArray(content)) {
    element.current = (
      <CustomEdges
        edges={content}
      />
    )
  } else {
    /* eslint indent: 0 */
    switch (content) {
      case Content.Posts:
        element.current = <AllPosts />
        break
      case Content.Projects:
        element.current = <AllProjects />
        break
      case Content.Tags:
        element.current = <AllTags />
        break
      default:
        element.current = null
        break
    }
  }

  return (
    <div className={styles.list}>
      <ol className="grid">
        {element.current}
      </ol>
    </div>
  )
}

Collection.defaultProps = {
  content: Content.Posts,
  filter: Filter.All,
  layout: Layout.List,
}

export default Collection
