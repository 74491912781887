/**
 * @public
 * Determines the content returned by the useContent hook
 */
export const Content = {
  Posts: 'Posts',
  Projects: 'Projects',
  Tags: 'Tags',
}

/**
 * @public
 * Filters content in useContent hook
 */
export const Filter = {
  All: 'All',
  Recent: 'Recent',
}

/**
 * @public
 */
export const Layout = {
  List: 'List',
  Grid: 'Grid',
}

export const PageLayout = {
  FixedWidth: 'FixedWidth',
  FullWidth: 'FullWidth',
}
