import React from 'react'
import Site from 'components/site'
import Page from 'components/page'
import PageHeader from 'components/page/page-header'
import List from 'components/list'
import useAllPosts from 'hooks/use-all-posts'


const Posts = () => {
  const allMdx = useAllPosts()

  return (
    <Site>
      <Page title="Posts" layout={false}>
        <PageHeader
          title="Writing about design"
          subtitleAfter={allMdx.length > 0 ? null : "There's no new content right now, but stay posted."}
        />
        <List />
      </Page>
    </Site >
  )
}

export default Posts
