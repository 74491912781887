import React, { useRef } from 'react'
import { Link } from 'gatsby'
import Image from 'gatsby-image'
import styles from './list-item.module.css'

// Prop names based on iOS table views
// https://developer.apple.com/design/human-interface-guidelines/ios/views/tables/
const ListItem = ({ image, title, subtitle, subtitles, path }) => {
  const subtitleRef = useRef(null)

  if (Array.isArray(subtitles)) {
    subtitleRef.current = (
      <>
        {subtitles.map((subtitle, index) => {
          return (
            <div
              key={index}
              className={styles.listItemSubtitle}
            >
              {subtitle}
            </div>
          )
        })}
      </>
    )
  } else {
    subtitleRef.current = (
      <div className={styles.listItemSubtitle}>{subtitle}</div>
    )
  }

  return (
    <div className="grid-span-5 grid-small-span-10">
      <Link
        to={path}
        className={styles.listItemLink}
      >
        <div className={styles.listItem}>
          {image && (
            <Image
              className={styles.listItemImage}
              fluid={image.childImageSharp.fluid}
            />
          )}
          <div className={styles.listItemTitle}>{title}</div>
          {subtitleRef.current}
        </div>
      </Link>
    </div>
  )
}

export default ListItem
