import { useStaticQuery, graphql } from 'gatsby'

const usePosts = () => {
  const { allMdx } = useStaticQuery(graphql`
    query AllPosts {
      allMdx(
        sort: { fields: [frontmatter___publishTime], order: DESC }
        filter: { 
          frontmatter: { 
            template: { eq: "post" } 
            published: { eq: true } 
            archived: { eq: false } 
          } 
        }
      ) {
        edges {
          node {
            id
            fields {
              path
            }
            frontmatter {
              title
              description
              displayTime: publishTime(formatString: "MMMM Do, YYYY")
              image {
                id
                childImageSharp {
                  fluid(maxHeight: 800) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            excerpt(pruneLength: 200, truncate: false)
          }
        }
      }
    }
  `)

  return allMdx ? allMdx.edges.map(({ node }) => node) : null
}

export default usePosts
