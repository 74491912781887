import { useStaticQuery, graphql } from 'gatsby'

const useTags = () => {
  const { allMdx } = useStaticQuery(graphql`
    query AllTags {
      allMdx {
        edges: group(field: frontmatter___tags) {
          title: fieldValue
          count: totalCount
        }
      }
    }
  `)

  return allMdx ? allMdx.edges : null
}

export default useTags
