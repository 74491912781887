import { useStaticQuery, graphql } from 'gatsby'

const useProjects = () => {
  const { allMdx } = useStaticQuery(graphql`
    query AllProjects {
      allMdx(
        sort: { fields: [frontmatter___publishTime], order: DESC }
        filter: { frontmatter: { template: { eq: "project" } } }
      ) {
        edges {
          node {
            id
            fields {
              path
            }
            frontmatter {
              title
              description
              displayTime: publishTime(formatString: "MMMM Do, YYYY")
              displayYear: publishTime(formatString: "YYYY")
            }
          }
        }
      }
    }
  `)

  return allMdx ? allMdx.edges.map(({ node }) => node) : null
}

export default useProjects
